<template>
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> Active Vendor List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Active Vendor</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
      <b-card>
      <b-row>
        <b-col lg="2">
          <!-- <b-form-group
            label="Sort : "
            label-for="sort-by-select"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-50"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
  
             
            </b-input-group>
          </b-form-group> -->
        </b-col>
        <b-col lg="9" class="text-right">
        <!--   <b-form-group
            v-model="sortDirection"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="4"
            label-align-sm="center"
            description-align-sm="center"
            class="mb-1"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
            &nbsp; Filter On &nbsp; : &nbsp; <b-form-checkbox value="name">Name</b-form-checkbox>
              <b-form-checkbox value="total_invoice">Mobile </b-form-checkbox>
              <b-form-checkbox value="total_invoiced">  Pincode </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group> -->
          
        </b-col>
        <b-col lg="1" class="text-right">
          <button  class="btn btn-success float-right btn-sm" v-b-modal.modal-lg >Bill Generate</button> 
          <!-- <a href="https://apitest.venuebook.in/admin/export_leads" download class="btn btn-success float-right btn-sm" >Export Excel</a>  -->
        </b-col>
        <b-col lg="3" class="my-1">
          
        <!--  <b-button v-b-modal.modal-1 class="btn btn-info float-right btn-sm">Upload Vendor Excel</b-button> -->
           



        </b-col>
  
       
 
      
    </b-row>
  <b-row>
        <b-col sm="1" md="1">
          <b-form-group
          
            label-for="per-page-select"
            
            label-align-sm="left"
            label-size="sm"
            class="mb-2"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>

          
  
        </b-col>
        <b-col lg="5" >
            
         
        </b-col>
        <b-col lg="6" >
        
          <b-row>
            <b-col lg="3" >

</b-col>
            <b-col lg="9" >
              <b-form-group
            
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
  
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
            </b-col>
          <!--   <b-col lg="2" >
              <b-button v-b-modal.modal-edit class="btn btn-info float-right btn-sm">Create New</b-button> 
            </b-col> -->
          </b-row>
          
        </b-col>
  
      </b-row>
      <b-skeleton-table v-if="load_leads"
      sticky-header=true
      small
  :rows="10"
  :columns="6"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
      <!-- Main table element -->
      <b-table
      v-if="!load_leads"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        :busy.sync="isBusy"
      >
      
      
      
      <template #cell(Action)="data">
   <button class="btn btn-info btn-sm" @click="view_vendor_data(data.item.total_evnt_det)">
     View
   </button> 
            
          </template>
          
         
     
  
       
      </b-table>

      <b-col sm="2" md="2" class="my-1 float-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            @change="handlePageChange"
            prev-text="Prev"
            next-text="Next"
          ></b-pagination>
        </b-col>
    </b-card>
      <!-- Info modal -->
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>


    <b-modal ref="Bill_invoice" id="modal-lg" size="lg" title="" centered hide-header @ok="bill_submit($event)" :ok-disabled="bill_id.length<=0" :ok-title="'Generate Bill'">
      <h3>Bill Generate</h3>
    
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Ref no#</th>
            <th scope="col">Event Date</th>
            <th scope="col">Invoice date</th>
            <th scope="col" class="text-right">Total amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vndr,v) in not_bill_generate" :key="v">
            <th scope="row">
              <input type="checkbox" v-model="bill_id" :value="vndr"  @change="change_amount"  v-if="vndr.vb_invoice_date==null">
              <span v-else>#</span>
            </th>
            <td>{{ vndr.booking_auto_id.split('-')[1] }}</td>
            <td>{{ vndr.from_date }}</td>
            <td>{{ vndr.invoice_date }}</td>
            <td class="text-right">{{ vndr.total_booking_price - vndr.applied_discount }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-right">Total</td>
            <td class="text-right">{{ total_amount }}</td>
          </tr>
          <tr v-if="bill_id.length>0">
            <td colspan="4" class="text-right">Billing Total</td>
            <td class="text-right">{{ billing_total }}</td> 
          </tr>
          <tr v-if="bill_id.length>0">
            <td colspan="4" class="text-right">Percentage</td>
            <td class="text-right">{{ vendor_percentage }}</td>
          </tr>
          <tr v-if="bill_id.length>0">
            <td colspan="4" class="text-right">Bill Amount</td>
            <td class="text-right fw-bold">{{ Bill_amount }}</td>
          </tr>
        </tfoot>
      </table>
    </b-modal>

    </b-container>
  </template>
  
  <script>
   import axios from "axios";
   import { validationMixin } from "vuelidate";
   import { mapState } from "vuex";
import { required,
		minLength,
		helpers,
		maxLength,
		numeric} from "vuelidate/lib/validators";

    const nameCheck = helpers.regex(
    "nameCheck",
    /^[a-z A-Z]+(?:-[a-z A-Z]+)*$/
	);	

    export default {
        mixins: [validationMixin],
      data() {
        return {
        /*   items: [
            { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
            { isActive: false, age: 21, name: { first: 'Larsen', last: 'Shaw' } },
            {
              isActive: false,
              age: 9,
              name: { first: 'Mini', last: 'Navarro' },
              _rowVariant: 'success'
            },
            { isActive: false, age: 89, name: { first: 'Geneva', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'Jami', last: 'Carney' } },
            { isActive: false, age: 27, name: { first: 'Essie', last: 'Dunlap' } },
            { isActive: true, age: 40, name: { first: 'Thor', last: 'Macdonald' } },
            {
              isActive: true,
              age: 87,
              name: { first: 'Larsen', last: 'Shaw' },
              _cellVariants: { age: 'danger', isActive: 'warning' }
            },
            { isActive: false, age: 26, name: { first: 'Mitzi', last: 'Navarro' } },
            { isActive: false, age: 22, name: { first: 'Genevieve', last: 'Wilson' } },
            { isActive: true, age: 38, name: { first: 'John', last: 'Carney' } },
            { isActive: false, age: 29, name: { first: 'Dick', last: 'Dunlap' } }
          ], */
          bill_id:[],
          items:[],
          fields: [
            { key: 'Month', label: 'Month', sortable: true, class: 'text-left' },
            { key: 'Count', label: 'No Of Events', sortable: true, sortDirection: 'desc' ,class: 'text-center' },
            
            { key: 'invoice_generate', label: 'Invoice Generated', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'completed', label: 'Invoice Not Done', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'billg', label: 'Bill Generate', sortable: true, sortDirection: 'desc'  ,class: 'text-center' },
            { key: 'Action', label: 'Action' },
           
            
         
          ],
          favourites:false,
          vendor_disabled:false,
          totalRows: 1,
          currentPage: 1,
          perPage: 15,
          pageOptions: [10, 15 , 20, 50, { value: 100, text: "All" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
          lead_sorce:true,
          lead_source: [],
          selecttype:'+91',
          infoModal: {
            id: 'info-modal',
            title: '',
            content: ''
          },
          load_leads:true,
          isBusy:false,
          uploadFieldName:"",
          importFile: {
      import_file: [],
    },
    vendor:
    {
      name:'',
      mob:'',
      landmob:'',
      place:'',
      location:'',
      pincode:'',
      source:'',
      
    },
    not_bill_generate:[],
    total_amount:0,
    billing_total:0,
    Bill_amount:0,
    vendor_percentage:0
        }
      },
      validations: {
    importFile: {
      import_file: { required },
    },
    vendor:
        {
          name:{
           // required,
            nameCheck
          },
          mob:{
            required,
            numeric,
            
            minLength: minLength(10),
            maxLength: maxLength(10),
          }, 
          landmob:{
           // required,
            numeric,
            
            minLength: minLength(11),
            maxLength: maxLength(11),
          },
          place:{
            required
          },
          location:{
            required
          },
          source:{
            required
          },
          pincode:{
            required,
            numeric,
					minLength: minLength(6),
					maxLength: maxLength(6),
          },
          
        },
  },
  computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            })
        },
        ...mapState(["checkLoadData"]),
      },
     
      methods: {
        info(item, index, button) {
            item, index, button
         /*  this.infoModal.title = `Row index: ${index}`
          this.infoModal.content = JSON.stringify(item, null, 2)
          this.$root.$emit('bv::show::modal', this.infoModal.id, button) */
          this.$router.push('/Sales/view_sales_details/'+item)
        },
        resetInfoModal() {
          this.infoModal.title = ''
          this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        validatevendorState(name) {
				const { $dirty, $error } = this.$v.vendor[name];
				return $dirty ? !$error : null;
			},
       /*  async handlePageChange()
        {
//alert(this.currentPage)
        }, */
        async revenue_list()
        {
            this.isBusy = true
             axios.post("/admin/active_vendor_details",
             {
              vendor_id:this.$route.params.id
             }).then((resp) => {
        this.items = [];
        this.lead_source = [];
        this.totalRows = resp.data.event_details.length
        this.isBusy = false;
        this.load_leads = false;
            for(var i=0; i < resp.data.event_details.length;i++)
            {
                this.items.push({
                  id:0,
                  Month:resp.data.event_details[i].Month,
                  Count:resp.data.event_details[i].count,
                  completed:resp.data.event_details[i].event_done,
                  invoice_generate:resp.data.event_details[i].event_invoice_done,
                  notdone:resp.data.event_details[i].event_not_done,
                  billg:resp.data.event_details[i].bill_generated,
                  total_evnt_det:resp.data.event_details[i].total_evnt_det,
                  pending_evnt_det:resp.data.event_details[i].pending_evnt_det,
                });

            }
            this.not_bill_generate = resp.data.pending_evnt_det;
            this.vendor_percentage = 0.9;
            var total_amount=0;
            for(var j=0; j < this.not_bill_generate.length;j++)
            {
              total_amount+=this.not_bill_generate[j].total_booking_price - this.not_bill_generate[j].applied_discount;//vndr.total_booking_price - vndr.applied_discount
            }
this.total_amount = total_amount;
            
        });
        },
        getImportData(e) {
      e.preventDefault();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.$v.importFile.$touch();
      if (this.$v.importFile.$anyError) {
        return;
      } else {
        let data = new FormData();
        data.append("import_file", this.importFile.import_file);
        axios.post("/admin/Sales_vendor_insert", data, config).then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status == true) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              //this.close();
              this.revenue_list();
              this.$bvModal.hide("modal-1");
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          }
        });
      }
    },

    closeFile() {
      this.$nextTick(() => {
        this.$bvModal.hide("ImportPincodeModal");
        this.importFile = {
          import_file: [],
        };
        this.$v.$reset();
      });
    },
    validateFileState(import_file) {
      const { $dirty, $error } = this.$v.importFile[import_file];
      return $dirty ? !$error : null;
    },

    Vendor_create(bvModalEvent)
      {
        bvModalEvent.preventDefault();
        this.selecttype=='+91' ? this.vendor.landmob = "00000000000" : this.vendor.mob = "0000000000";
        this.$v.vendor.$touch();
				if (this.$v.vendor.$anyError) {
					return;
					} else {
            this.vendor_disabled=true;
        axios.post("/admin/Vendor_create",
				{
					name:this.vendor.name,
					number:this.selecttype=='+91' ?  this.vendor.mob : this.vendor.landmob,
					place:this.vendor.place,
					location:this.vendor.location,
					pincode:this.vendor.pincode,
					source:this.vendor.source,
					vendor_id : this.vendor_id,
          lead_sorce:this.lead_sorce,
          selecttype:this.selecttype,
				})
				.then((resp) => {
          this.vendor_disabled=false;
					resp;
          this.lead_sorce=true;
          this.vendor.name='';
          this.vendor.mob='';
          this.vendor.place='';
          this.vendor.location='';
          this.vendor.pincode='';
          this.vendor.source='';
          this.vendor_id='';
					
          this.$bvModal.hide("modal-edit");

          if (resp.data.status) {
                        this.$root.$refs.app.showToast("success",'Vendor Created Successfull');
                      //  this.$router.push("/login");
						} else {
                        this.$root.$refs.app.showToast("danger", resp.data.message);
					}
          //message
         // this.$root.$refs.app.showToast("success", "Vendor Created Successfull");
          this.revenue_list();
				});
      }
      },
      toogle_favrate(id,status)
      {
        axios.post("/admin/vendor_favarotes",
				{
					id:id,
          status:status
				})
				.then((resp) => {
          resp;
          this.revenue_list();
        })
      },
      add_new_source()
      {
          this.lead_sorce=!this.lead_sorce;
      },
      favourites_change(status)
      {
        this.favourites=status;
        this.revenue_list();
      },
      exportTable() {
        axios
    .post('admin/export_leads', {
        responseType: "blob"
      })
    .then(response => {
      console.log(new Blob([response.data]));
      const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'abcd.xlsx')
          document.body.appendChild(link)
          link.click()
    })
    },
    view_vendor_data(data)
    {
      this.$store.commit("SET_LOAD_DATA",data); 
      this.$router.push('/Manage_vendors/load_vendor_details')
    },
    bill_submit(e)
    {
      e.preventDefault();

      axios.post("/admin/Vendor_bill_generate_create",
				{
					bill_id:this.bill_id,
					billing_total:this.billing_total,
					vendor_percentage:this.vendor_percentage,
					Bill_amount:this.Bill_amount,
				})
				.then(() => {
          this.$root.$refs.app.showToast("success",'Bill Generated');
          this.revenue_list();
          this.$bvModal.hide("modal-lg");
        });

    },
    change_amount()
    {
      var billing_total =0;
       for(var t=0;t<this.bill_id.length;t++)
       {
        billing_total+= this.bill_id[t].total_booking_price - this.bill_id[t].applied_discount;
       }
       this.billing_total = billing_total;

       this.Bill_amount = this.billing_total*this.vendor_percentage/100;  
    }
    
      },
      mounted() {
        this.revenue_list();
        
      },
    }
  </script>
  <style>
  .fs-10
  {
    font-size: 15px;
  }
  .mdi-color
  {
    color:goldenrod;
  }
  </style>